import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { Link } from '../link'
import { Box } from '../box'
import { Title } from '../title'

import pinImg from '../../assets/pin.svg'
import lokalImg from '../../assets/lokal.svg'
import fairImg from '../../assets/fair.svg'
import lokalFairImg from '../../assets/lokal-fair.svg'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Pin = styled.a`
  display: block;
  margin-left: auto;
`

const PinImg = styled.img`
  display: block;
`

const Media = styled.div`
  width: 100%;
`

const Badge = styled.img`
  width: 68px;
  height: auto;
  display: block;
  position: absolute;
  top: -44px;
  right: 14px;
`

const Image = styled(Img)`
  display: block;
`

const StyledBox = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  /** IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: auto;
  }
`

const Text = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`

export function Actor({ actor }) {
  let badgeImg = actor.local && actor.fair ? lokalFairImg : null

  if (actor.local && !actor.fair) {
    badgeImg = lokalImg
  }

  if (actor.fair && !actor.local) {
    badgeImg = fairImg
  }

  if (actor.url) {
    // Remove protocol
    var niceUrl = actor.url.replace(/(^\w+:|^)\/\//, '')

    // Remove traling slash
    niceUrl = niceUrl.replace(/\/$/, '')
  }

  const googleMapsUrl = `https://www.google.com/maps/place/${actor.street}, ${actor.zip}, ${actor.city}/`

  return (
    <Wrapper>
      <Media>
        <Image
          fluid={actor.image.childImageSharp.fluid}
          alt=""
          fadeIn={false}
        />
      </Media>
      <StyledBox>
        <Badge src={badgeImg} alt="" />
        <Title size={2} heading="h2" margin="small">
          {actor.title}
        </Title>
        <Text>
          {actor.street}
          <br />
          {actor.zip} {actor.city}
          <br />
          Telefon <a href={`mailto:${actor.phone}`}>{actor.phone}</a>
          <br />
          <a href={`mailto:${actor.email}`}>{actor.email}</a>
          <br />
          <br />
        </Text>
        <Meta>
          {actor.url && (
            <Link to={actor.url} external arrow target="_blank">
              {niceUrl}
            </Link>
          )}
          <Pin href={googleMapsUrl} target="_blank">
            <PinImg src={pinImg} alt="" />
          </Pin>
        </Meta>
      </StyledBox>
    </Wrapper>
  )
}
