import React, { useState } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { Location } from './location'
import mapStyles from './map-styles.json'

import { config } from '../../../config'

const SETTINGS = {
  key: 'AIzaSyCNFwnL-xwsYKAyh8HvDPmNEkpdG7k1Cu0',
  center: {
    lat: 47.14151,
    lng: 9.52154,
  },
  zoom: 16,
  options: {
    styles: mapStyles,
    scrollwheel: false,
  },
}

const Wrapper = styled.div`
  height: 480px;
  width: 100%;
  mix-blend-mode: multiply;

  @media (min-width: ${config.breakpoints.tablet}) {
    height: 750px;
  }
`

export function Map({ actors }) {
  const [openId, setOpenId] = useState(null)

  function handleOpen(id) {
    setOpenId(id)
  }

  function handleClose(id) {
    setOpenId(null)
  }

  return (
    <Wrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: SETTINGS.key }}
        defaultCenter={SETTINGS.center}
        defaultZoom={SETTINGS.zoom}
        options={SETTINGS.options}
      >
        {actors.map((actor) => (
          <Location
            key={actor.id}
            actor={actor}
            lat={actor.lat}
            lng={actor.lng}
            show={openId === actor.id}
            onOpen={() => {
              handleOpen(actor.id)
            }}
            onClose={() => {
              handleClose(actor.id)
            }}
          />
        ))}
      </GoogleMapReact>
    </Wrapper>
  )
}
