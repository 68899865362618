import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Actors } from '../components/actors'

export default function ActorsPage({ data }) {
  const actors = data.allStrapiActor.edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title="Akteure" />
      <Actors actors={actors} />
    </Layout>
  )
}

export const query = graphql`
  query GetActors {
    allStrapiActor(filter: { active: { eq: true } }) {
      edges {
        node {
          title
          street
          categories {
            id
            title
          }
          phone
          zip
          city
          local
          fair
          email
          url
          id
          lat
          lng
          image {
            childImageSharp {
              fluid(maxWidth: 746, maxHeight: 426, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
