import React from 'react'
import styled, { css } from 'styled-components'

import { config } from '../../../config'
import { Marker } from './marker'

const Wrapper = styled.div`
  position: relative;
  transform: translate(-25px, -35px);
  z-index: 10;

  ${(props) =>
    props.show &&
    css`
      z-index: 11;
    `}
`

const StyledMarker = styled(Marker)`
  width: 25px;
  height: 35px;
  display: block;
  cursor: pointer;
  color: ${config.colors.base};

  &:hover {
    color: ${config.colors.primary};
  }
`

const Box = styled.div`
  border: 1px solid red;
  width: 280px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #2c5664;
  line-height: 1.4;
  padding: 8px;
  font-size: 17px;
  font-family: inherit;
  position: absolute;
  left: 46px;
  top: 15px;
  transform: translateY(-50%);

  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 12px;
    margin-top: -12px;
  }

  &:before {
    border-color: rgba(44, 86, 100, 0);
    border-right-color: #2c5664;
    border-width: 13px;
    margin-top: -13px;
  }
`

const Close = styled.button`
  border: 0;
  background: transparent;
  font-size: 24px;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${config.colors.base};
  cursor: pointer;
  outline: none;
`

// Usage: `<Location key={actor.id} actor={actor} />`
export function Location({ actor, show = false, onOpen, onClose, ...props }) {
  return (
    <Wrapper lat={actor.lat} lng={actor.lng} show={show} {...props}>
      {show && (
        <Box>
          <Close onClick={onClose}>×</Close>
          <strong>{actor.title}</strong>
          <br />
          {actor.street}
          <br />
          {actor.zip} {actor.city}
        </Box>
      )}
      <StyledMarker onClick={onOpen} />
    </Wrapper>
  )
}
