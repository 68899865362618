import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: block;
  width: auto;
  height: 38px;

  .fill-transition {
    transition: fill 0.25s ease, color 0.25s ease;
  }
`

export function Marker({ ...props }) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 35" {...props}>
      <path
        d="M12.4994416.5c4.3173681.0006915 8.3017175 2.32008188 10.4342233 6.07402479 2.1325057 3.75394291 2.0845545 8.36396601-.1255749 12.07273891l-9.2825679 15.4991094c-.1790206.2993864-.501161.4837973-.8499774.4865742-.3541152 0-.6756227-.1783999-.8581209-.4723231L2.29156891 18.8096336c-2.2871514-3.701819-2.39142755-8.3516394-.27252488-12.15226792C4.13794669 2.85673722 8.14805965.5007453 12.4994416.5zM12.5 7c-2.76142375 0-5 2.23857625-5 5 0 2.7614237 2.23857625 5 5 5 2.7614237 0 5-2.2385763 5-5-.0028056-2.76026079-2.2397392-4.99719438-5-5z"
        className="fill-transition"
        fill="currentColor"
      />
    </Svg>
  )
}
