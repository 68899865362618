import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { shuffle } from 'lodash'

import { config, actorFilterItems } from '../../config'

import { Actor } from './actor'
import { Map } from './map'
import { Container } from '../container'
import { Content } from '../content'
import { Filter } from '../filter'
import { Intro } from '../intro'

import iconImg from '../../assets/shop.svg'

const Wrapper = styled.div`
  margin-bottom: 30px;

  @media (min-width: ${config.breakpoints.desktop}) {
    margin-bottom: 146px;
  }
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;

  @media (min-width: ${config.breakpoints.desktop}) {
    margin: -25px;
    flex-direction: row;
  }
`

const Item = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: ${config.breakpoints.desktop}) {
    width: 50%;
    padding: 25px;
    margin-bottom: 0;
    display: flex;
  }

  @media (min-width: ${config.breakpoints.screen}) {
    width: 33.333333%;
    padding: 25px;
    margin-bottom: 0;
  }
`

/** Count how many actors belong to a category. */
function getCount(category, actors) {
  if (!category) {
    return
  }

  let count = 0
  actors.forEach((actor) => {
    actor.categories.forEach((cat) => {
      if (cat.title === category) {
        count = count + 1
      }
    })
  })

  return count
}

export function Actors({ actors: sourceActors }) {
  const [activeCategory, setActiveCategory] = useState(null)
  const [actors, setActors] = useState(sourceActors)

  useEffect(() => {
    setActors((actors) => {
      return shuffle(actors)
    })
  }, [])

  function onFilterChange(item) {
    setActiveCategory(item.key)
  }

  const filteredActors = !activeCategory
    ? actors
    : actors.filter((actor) => {
        // Check if activeCategory is included in actor.categories.title
        for (const category of actor.categories) {
          if (category.title === activeCategory) {
            return true
          }
        }

        return false
      })

  const actorFilterItemsWithCount = actorFilterItems.map((item) => {
    const count = getCount(item.key, actors)
    return {
      key: item.key,
      label: count ? `${item.label} (${count})` : item.label,
    }
  })

  return (
    <>
      <Wrapper>
        <Container>
          <Content
            sidebar={
              <Filter
                label="Kategorien"
                items={actorFilterItemsWithCount}
                defaultActive={activeCategory}
                onChange={onFilterChange}
              />
            }
          >
            <Intro icon={iconImg}>
              Hier finden Sie lokale und faire Produkte
            </Intro>
            <Items>
              {filteredActors.map((actor) => (
                <Item key={actor.id}>
                  <Actor actor={actor} />
                </Item>
              ))}
            </Items>
          </Content>
        </Container>
      </Wrapper>
      <Map actors={filteredActors} />
    </>
  )
}
